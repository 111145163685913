<template>
  <div class="d-flex flex-column flex-grow-1 mr-2 mb-2 rounded">
    <BaseToolBar title="Stocks" subtitle="Liste des stocks disponibles">
      <BaseButton
        class="btn btn-primary ml-3"
        v-tooltip="'Ajouter une phase'"
        @click="storeStock"
        :loading="loading"
        icon="plus"
      ></BaseButton>
    </BaseToolBar>
    <div class="d-flex flex-column flex-md-row w-100">
      <div class="flex-grow-1 d-flex flex-column">
        <div v-for="stock in stocks" :key="stock.id">
          <stocks-item :stock="stock"></stocks-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMultiRowFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

import BaseButton from "@/components/bases/Button.vue";
import StocksItem from "@/components/materiels/StocksItem.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";

export default {
  components: {
    StocksItem,
    BaseToolBar,
    BaseButton
  },
  data() {
    return {
      loading: false,
      feedback: {}
    };
  },
  methods: {
    ...mapActions({
      _storeStock: "materiel/createStock"
    }),
    storeStock: function() {
      this.feedback = {};
      this.loading = true;
      this._storeStock()
        //.catch(error => ())
        .finally(() => (this.loading = false));
    }
  },
  mounted() {},
  computed: {
    ...mapGetters({
      data: "materiel/data"
    }),
    ...mapMultiRowFields("materiel", ["stocks"])
  }
};
</script>
<style lang="css">
</style>